<template>
  <div class="border-black/6 p-2" :class="{ 'border-b': borderBottom }">
    <NuxtLink
      class="flex w-full cursor-pointer items-center p-2 hover:rounded-lg hover:bg-primary-default/[.15] md:p-3"
      :to="localePath({ name: `shop-dashboard-${tab.path}` })"
      :class="{
        'md:rounded-lg md:bg-primary-default/[.15]': isActive,
      }"
      @click="emit('set-selected-tab', tab)"
    >
      <StaticPictogram
        v-if="tab.icon"
        :name="tab.icon"
        :size="iconSize"
        class="pr-3"
      />
      <span class="font-semibold">
        {{ tab.path ? $t('account.dashboard.' + tab.path) : tab.name }}
      </span>
      <StaticPictogram
        name="chevron-right"
        class="ml-auto"
        :class="{ 'md:hidden': !showArrowOnDesktop }"
        size="lg"
      />
    </NuxtLink>
  </div>
</template>
<script setup lang="ts">
import type { Props as pictogramSizeClass } from '~/components/ui/reactive/StaticPictogram.vue';

const emit = defineEmits(['set-selected-tab']);
const localePath = useLocalePath();

defineProps({
  tab: {
    type: Object,
    required: true,
  },
  isActive: {
    type: Boolean,
    required: false,
  },
  borderBottom: {
    type: Boolean,
    default: true,
  },
  iconSize: {
    type: String as PropType<pictogramSizeClass['size']>,
    default: 'lg',
  },
  showArrowOnDesktop: {
    type: Boolean,
    default: false,
  },
});
</script>
